<template>

    <div class="progressicon">
        <div class="progressicon__value">
            {{progress}}
        </div>

        <svg width="108px" height="128px" viewBox="0 0 108 128" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :class="'progress--' + progressRounded">
            <title>ProgressIcon</title>
            <defs>
                <path d="M6.0384548,33.1585991 L43.7637276,7.07692906 C49.9235137,2.81831154 58.0764863,2.81831154 64.2362724,7.07692906 L101.961545,33.1585991 C105.743013,35.7729476 108,40.0771978 108,44.6744056 L108,83.3255944 C108,87.9228022 105.743013,92.2270524 101.961545,94.8414009 L64.2362724,120.923071 C58.0764863,125.181688 49.9235137,125.181688 43.7637276,120.923071 L6.0384548,94.8414009 C2.25698657,92.2270524 0,87.9228022 0,83.3255944 L0,44.6744056 C0,40.0771978 2.25698657,35.7729476 6.0384548,33.1585991 Z" id="path-qcmfvr5azk-1"></path>
                <filter x="-35.2%" y="-50.0%" width="170.4%" height="211.8%" filterUnits="objectBoundingBox" id="filter-qcmfvr5azk-4">
                    <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.186598558 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <div>asd</div>
            <g id="Fitnessplan" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Fitnessplan-2.0-Motivation" transform="translate(-134.000000, -127.000000)">
                    <rect fill="#FFFFFF" x="0" y="0" width="375" height="1139"></rect>
                    <g id="Play-Icon" transform="translate(134.000000, 127.000000)">
                        <g id="Group-6" mask="url(#mask-qcmfvr5azk-2)">
                            <mask id="mask-qcmfvr5azk-2" fill="white">
                                <use xlink:href="#path-qcmfvr5azk-1"></use>
                            </mask>
                            <use id="progress-base" fill-opacity="0.08" fill="#1E1D1F" xlink:href="#path-qcmfvr5azk-1"></use>
                            <polygon id="progress-fill" fill="#F87918" points="0 4 108 4 108 124 0 124"></polygon>
                            <path d="M89.507,24.5479659 L101.961545,33.1585991 C105.743013,35.7729476 108,40.0771978 108,44.6744056 L108,83.3255944 C108,87.9228022 105.743013,92.2270524 101.961545,94.8414009 L64.2362724,120.923071 C61.7452839,122.645236 58.9283385,123.670965 56.0490671,124.000259 L51.9509329,124.000259 C49.0716615,123.670965 46.2547161,122.645236 43.7637276,120.923071 L18.493,103.451966 L89.507,24.5479659 Z" id="Path" fill="#000000" opacity="0.0632207961" mask="url(#mask-qcmfvr5azk-2)"></path>
                        </g>
                        <g id="50%" fill="#1E1D1F" fill-opacity="1">
                            <use filter="url(#filter-qcmfvr5azk-4)" xlink:href="#text-qcmfvr5azk-3"></use>
                            <use xlink:href="#text-qcmfvr5azk-3"></use>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>

</template>

<script>

    export default {
        name: "ProgressIcon",
        data() {
            return {
            };
        },
        props: {
            progress: {
                type: Number,
            }
        },
        computed: {
            progressRounded() {
                if (this.progress) {
                    return Math.round(this.progress / 5) * 5;
                }
                return 0;
            }
        }
    };

</script>

<style lang="scss">

    @keyframes progressAnimation-05 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(90%);
        }
    }

    @keyframes progressAnimation-10 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(87.5%);
        }
    }

    @keyframes progressAnimation-15 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(82.5%);
        }
    }

    @keyframes progressAnimation-20 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(77.5%);
        }
    }

    @keyframes progressAnimation-25 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(72.5%);
        }
    }

    @keyframes progressAnimation-30 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(67.5%);
        }
    }

    @keyframes progressAnimation-35 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(62.5%);
        }
    }

    @keyframes progressAnimation-40 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(57.5%);
        }
    }

    @keyframes progressAnimation-45 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(52.5%);
        }
    }

    @keyframes progressAnimation-50 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(47.5%);
        }
    }

    @keyframes progressAnimation-55 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(42.5%);
        }
    }

    @keyframes progressAnimation-60 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(37.5%);
        }
    }

    @keyframes progressAnimation-65 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(32.5%);
        }
    }

    @keyframes progressAnimation-70 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(27.5%);
        }
    }

    @keyframes progressAnimation-75 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(22.5%);
        }
    }

    @keyframes progressAnimation-80 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(17.5%);
        }
    }

    @keyframes progressAnimation-85 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(12.5%);
        }
    }

    @keyframes progressAnimation-90 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(8%);
        }
    }

    @keyframes progressAnimation-95 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(4%);
        }
    }

    @keyframes progressAnimation-100 {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0%);
        }
    }

    #progress-fill {
        fill: $colorPrimary;
        animation: 3s cubic-bezier(0.75, 0, 0.25, 1) forwards;

        .progress--5 & {
            animation-name: progressAnimation-05;
        }

        .progress--10 & {
            animation-name: progressAnimation-10;
        }

        .progress--15 & {
            animation-name: progressAnimation-15;
        }

        .progress--20 & {
            animation-name: progressAnimation-20;
        }

        .progress--25 & {
            animation-name: progressAnimation-25;
        }

        .progress--30 & {
            animation-name: progressAnimation-30;
        }

        .progress--35 & {
            animation-name: progressAnimation-35;
        }

        .progress--40 & {
            animation-name: progressAnimation-40;
        }

        .progress--45 & {
            animation-name: progressAnimation-45;
        }

        .progress--50 & {
            animation-name: progressAnimation-50;
        }

        .progress--55 & {
            animation-name: progressAnimation-55;
        }

        .progress--60 & {
            animation-name: progressAnimation-60;
        }

        .progress--65 & {
            animation-name: progressAnimation-65;
        }

        .progress--70 & {
            animation-name: progressAnimation-70;
        }

        .progress--75 & {
            animation-name: progressAnimation-75;
        }

        .progress--80 & {
            animation-name: progressAnimation-80;
        }

        .progress--85 & {
            animation-name: progressAnimation-85;
        }

        .progress--90 & {
            animation-name: progressAnimation-90;
        }

        .progress--95 & {
            animation-name: progressAnimation-95;
        }

        .progress--100 & {
            animation-name: progressAnimation-100;
        }
    }

    .progressicon {
        display: flex;
        position: relative;

        &__value {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 28px;
            letter-spacing: -0.58px;
            color: $colorBlack;
            font-weight: 900;
            padding-left: 8px;

            &:after {
                content: '%';
                font-size: 20px;
                margin-left: -6px;
            }
        }
    }
</style>