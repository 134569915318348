<template>
    <div class="challenge__success">

        <div class="challenge__success__header">
            <div class="challenge__success__header__progress">
                <ProgressIcon :progress="progress"></ProgressIcon>
            </div>
            <h1>{{ headline }}</h1>
            <p v-html="motivationText"></p>
            <router-link to="/" class="button button--small button--primary">
                Weiter geht's
            </router-link>
        </div>

        <div class="challenge__success__statistics">
            <div class="challenge__success__statistics__content">
                <h2>Statistik</h2>
                <p>Die Erfolge der bereits absolvierten Einheiten können sich schon sehen lassen!</p>

                <div class="challenge__success__statistics__wrapper">
                    <div class="challenge__success__statistics__item challenge__success__statistics__item--small">
                        <img src="@/assets/ic_64_flame.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                {{ totalEnergyExpended.toLocaleString() }} <span>kcal</span>
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Aktivitätskalorien (inkl. optionale Workouts)
                            </div>
                        </div>
                    </div>
                    <div class="challenge__success__statistics__item challenge__success__statistics__item--small">
                        <img src="@/assets/ic_64_stopclock.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                {{ totalHours }}<span>h</span> {{ totalMinutes }}<span>m</span>
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Trainingsdauer (inkl. optionale Workouts)
                            </div>
                        </div>
                    </div>
                    <div class="challenge__success__statistics__item challenge__success__statistics__item--small">
                        <img src="@/assets/ic_64_progress.svg" alt="">
                        <div class="challenge__success__statistics__item__left">
                            <div class="challenge__success__statistics__item__value">
                                {{ this.numberOfFinishedExercises}}/{{ numberOfExercises }} <span>Einheiten</span>
                            </div>
                            <div class="challenge__success__statistics__item__label">
                                Bisher absolviert (ohne optionale Einheiten)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressIcon from '@/components/ProgressIcon';

    export default {
        name: "FitnessplanChallengeIntermediate",
        data() {
            return {
                fitnessPlan: null,
                challenges: null,
                numberOfExercises: 0,
                numberOfFinishedExercises: 0,
                progress: 0,
                totalTime: 0,
                totalEnergyExpended: 0,
                totalMinutes: 0,
                totalHours: 0,
                headline: "",
                motivationText: "",
            };
        },
        components: {
            ProgressIcon
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                let uniqueChallengeId = this.$route.query.unique_challenge_id;
                let motivationId = parseInt(this.$route.query.motivationId);

                await this.$store.dispatch("fetchChallenges").then(() => {
                    this.challenges = this.$store.getters.getChallenges;
                });
                await this.$store.dispatch('fetchFitnessPlan').then((res) => {
                    if (res.length !== 0) {
                        this.fitnessPlan = res[0];
                        this.fitnessPlan.items.filter(el => el.uniqueChallengeId === uniqueChallengeId).forEach(el => {
                            if (!el.isOptionalExercise) {
                                this.numberOfExercises++;
                            }
                        });
                        if (this.fitnessPlan.startedActivities) {
                            this.fitnessPlan.startedActivities.filter(el => el._uniqueChallengeId === uniqueChallengeId).forEach(el => {
                                if (!el.isOptionalExercise && el.activityState === "finished") {
                                    this.numberOfFinishedExercises++;
                                }
                                if (el.realDuration) {
                                    this.totalTime += el.realDuration;
                                }
                                if (el.energyExpended) {
                                    this.totalEnergyExpended += el.energyExpended;
                                }
                            });
                        }
                        this.totalHours = Math.floor(this.totalTime / 60);
                        this.totalMinutes = Math.round(this.totalTime % 60);
                        this.progress = Math.round(this.numberOfFinishedExercises / this.numberOfExercises * 100);
                    }
                });
                let challenge = this.fitnessPlan.startedActivities.find(challenge => challenge._uniqueChallengeId === uniqueChallengeId);
                if (challenge) {
                    let currentChallenge = this.challenges.find(item => item.id == challenge.challengeId);
                    let motivationTexts = currentChallenge.motivationtexts;
                    let motivationText = motivationTexts.filter(text => text.id == motivationId);
                    this.headline = motivationText[0].headline;
                    this.motivationText = motivationText[0].motivationtext;
                    let displayedMotivation = {
                        uniqueChallengeId: uniqueChallengeId,
                        motivationId: motivationId
                    };
                    this.$store.commit('markMotivationTextAsDisplayed', displayedMotivation);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .challenge__success {
        margin-bottom: -20px !important;
    }
</style>
